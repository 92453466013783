@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --medium-vertical-border: 30px;
    --high-vertical-border: 120px;
}

body,
html,
#root {
    height: 100%;
}

body {
    margin: 0;
    background: #1c1e2f;
    font-family: var(--font-family);
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: inherit;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.p-datatable {
    overflow-x: auto;
}

.p-datatable .p-datatable-tbody td {
    max-width: 500px;
    overflow-wrap: break-word;
}

.hash-test {
    margin: auto;
}

.p-accordion-header-text {
    width: 100%;
}

.p-column-filter-menu {
    margin-left: 0px !important;
}

/* Scroll options */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 px grey;
    border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--very-darkish-blue);
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--dark-pale-grey);
}
